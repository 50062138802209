import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import linda_img_1 from '../../../images/LindaScruggs.jpg';
import linda_img_1_mobile from '../../../images/LindaScruggs_mobile.jpg';

const fullName = "Linda H. Scruggs";

const copyList = [

    {
        type: "image",
        content: {
            src: linda_img_1,
            mobileSrc: linda_img_1_mobile,
            alt: "Linda H. Scruggs",
            caption: "Linda H. Scruggs"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title\">A conversation with Linda H. Scruggs, founding director of Ribbon"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Warrior"
    // },

    {
        type: 'text',
        content: `When Linda H. Scruggs looks back on her childhood in the Maryland and Washington, D.C. area, the long-time HIV 
        survivor, advocate, and co-executive director of Ribbon,* a national nonprofit, easily recalls 
        good times, like vacations and road trips that still make her smile. As for bad times, those memories are 
        just as lucid. A survivor of childhood molestation, “I discovered men could be cruel.”`
    },
    {
        type: 'text',
        content: "In the face of that discovery, she sought agency. “I learned to utilize control over my body to help myself.” For years, she saw sex as transactional, and as a young adult, she became the victim of rape and turned to drug use. Then one day, she recalls hearing the voice of her deceased grandfather. “He was the only person I believed loved me. He said, ‘Go home.’ I walked to my parents’ house and never used drugs or my body as my own economy again.”"
    },
    {
        type: "text",
        content: "Two years later, Scruggs was in a healthy relationship and pregnant when her world changed forever. While visiting a clinic, she was told very coldly that she’d tested positive for HIV, an experience she calls “traumatic.” Then, “they said, ‘You can terminate the baby today.’ I felt disempowered.” Linda persevered through this challenge, refusing to give up her child. “My son is 32 now and HIV negative. The day I got his negative diagnosis was the day that I had to do something.”"
    },
    {
        type: "text",
        content: "The year was 1991, and Scruggs’ first act of advocacy was on behalf of a 5-year-old girl named Brianna who was HIV positive and just wanted to go to school. “Her nurse practitioner and I got together and convinced a vice principal we knew to let Brianna attend for three days.” That triumph was when she found her voice and her calling. Scruggs began working alongside nurses at Johns Hopkins, educating and advocating for pregnant moms and children living with HIV to help improve their lives. She also helped improve their perspectives. “All the women’s groups at the time were talking about dying, and all the gay guys were talking about living. I always tell folks my gift to Baltimore was helping women learn to have vision.”"
    },
    {
        type: "blockquote",
        content: "“I always tell folks my gift to Baltimore was helping women learn to have vision.”",
        author: fullName
    },
    {
        type: "text",
        content: `For one program, “I loaded up a school bus and took 30 women and their kids to the beach,” she says, wanting 
        to give them some joyful release. “There was nothing like watching those kids see the 
        ocean for the first time.” Scruggs went on to other HIV/AIDS organizations like AIDS Alliance for 
        Children, Youth, and Families before forming her own, where she believed she could 
        have the greatest impact. Ribbon is in its 11th year of establishing and supporting programs and initiatives to arm 
        women and other forgotten populations of the HIV epidemic, like heterosexual Black men, with what they need 
        to survive and thrive. If it’s in HIV advocacy, Scruggs has done it, all while 
        using her story to inspire. Rather than a victim, “I’m a healer.”`
    },
    {
      type: "disclaimer",
    },
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">LINDA H. SCRUGGS"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">Ribbon"
    // },
    {
        type: "championsofchangelandinglink",
          content: "<a class=\"championsofchangelandinglink\" href=\"/championsofchange\">Learn more about all our Champions of Change honorees "

    },

]


class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}

        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Linda H. Scruggs, The Warrior"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}
      

      </div>
    );
  }
}

export default Content;
